import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
import { ServiceService } from '../service.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  Obj: { 'email': any; };
  showPassOrText: boolean;
  showEyeOrCrossEye: boolean;
  emailRegex: any = '^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$';
  // email: '[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,5}'
  minlen:any =/^.{6,}$/;
  pass: any = /^(?=.*[A-Z][a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;

  constructor(
    private router: Router,

    private fb: FormBuilder,
    private http: HttpClient,
    public service: ServiceService
  ) { }

  ngOnInit() {
    this.checkInput()
  }

  checkInput() {
    let remData = JSON.parse(localStorage.getItem('rememberMe')) ? JSON.parse(localStorage.getItem('rememberMe')) : '';
    let pass = '';
    if (remData != '') {
      pass = window.atob(remData.password)
    
    }

    this.loginForm = this.fb.group({
      // email: [remData.email, Validators.required, Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,5})')],
      email: [ remData.email, Validators.compose([ Validators.required, Validators.pattern(this.emailRegex) ]) ],
      // email: [remData.email, Validators.required],
      password: [pass, Validators.compose([Validators.required,Validators.pattern(this.pass)])],
      rememberMe: (false)
    })

    // this.loginForm.valueChanges.subscribe((form: any) => {
    //   console.log("form: ", form);
    // });
  }



  onLogin() {
    console.log("loginForm: ", this.loginForm.get);
    this.service.showSpinner();
    this.service.postApiFunc("admin/login", {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      // deviceType: "Web",
      // "ipAddress": "",
      // "location": "",
      // "userAgent": "",
      // "deviceToken": "",
    }, 0).subscribe(
      (res: any) => {

        this.service.hideSpinner();
        if (res['responseCode'] == '200') {
          localStorage.setItem('Auth', res['result']['token']);
          localStorage.setItem('userId', res['result']['_id'])

          if (this.loginForm.value.rememberMe == true) {
            let remData = {
              "email": this.loginForm.value.email,
              "password": window.btoa(this.loginForm.value.password)
            }
            localStorage.setItem('rememberMe', JSON.stringify(remData))

          }

          this.service.changeLoginSub('login');
          // window.location.reload();
          this.router.navigate(['dashboard']);
        }
      },
      (err: any) => {

        this.service.hideSpinner();
        this.service.toasterErr(err.error.responseMessage);
      }
    )
    this.Obj = {
      'email': this.loginForm.value.email,

    }

    localStorage.setItem('data', JSON.stringify(this.Obj));

  }
  showPassword() {
    this.showPassOrText = !this.showPassOrText;
    this.showEyeOrCrossEye = !this.showEyeOrCrossEye
  }


}

